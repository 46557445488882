import { graphql, useStaticQuery } from 'gatsby';

export interface AdsService {
  id: string;
  title: string;
  fields: {
    icon: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useAdServices(): AdsService[] {
  const { allWpAdvertisingService } = useStaticQuery(graphql`
    query GET_ADS_SERVICES {
      allWpAdvertisingService {
        nodes {
          id
          title
          fields: advertisingServicesFields {
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allWpAdvertisingService.nodes;
}
