import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { useAdServices } from '../../../hooks/useAdServices';

const AdvertisingServices: FC = () => {
  const services = useAdServices();
  return (
    <Root>
      <StyledContainer>
        <Title>Integrated advertising services</Title>
        <ServicesList>
          {services.map(item => {
            return (
              <ServicesItem key={item.id}>
                <Image
                  localFile={item.fields.icon.localFile}
                  alt={item.title}
                />
              </ServicesItem>
            );
          })}
        </ServicesList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 95px;
  }
  @media (max-width: 500px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.black3};
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 45px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 36px;
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const ServicesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ServicesItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: 120px;
  margin: 20px;
  @media (max-width: 768px) {
    min-width: auto;
    margin: 10px;
  }
  @media (max-width: 700px) {
    max-width: 140px;
  }
`;

export default AdvertisingServices;
