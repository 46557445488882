import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface DashboardProps {
  data: {
    title: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const Dashboard: FC<DashboardProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <Content>
          <Title>{data.title}</Title>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </Content>
        <ImageWrap>
          <StyledImage localFile={data.image.localFile} alt={data.title} />
        </ImageWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  display: flex;

  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 112px;
  padding-right: 6%;
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 0;
  }
  @media (max-width: 767px) {
    padding-top: 50px;
    padding-right: 16px;
    padding-bottom: 70px;
    flex-direction: column;
  }
`;

const Content = styled.div`
  max-width: 430px;
  margin-right: 30px;
  margin-top: -7px;
  @media (max-width: 1024px) {
    max-width: 360px;
    margin-right: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: none;
    margin-bottom: 55px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  margin-bottom: 60px;
  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 35px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const ImageWrap = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -22%;
    left: 34%;
    width: 627px;
    height: 627px;
    background-color: ${COLORS.red};
    border-radius: 50%;
  }
  @media (max-width: 1024px) {
    margin-right: -15px;
    &::before {
      width: 397px;
      height: 397px;
    }
  }
  @media (max-width: 767px) {
    align-self: center;
    margin-right: 0;
    &::before {
      width: 265px;
      height: 265px;
      left: 50%;
      top: -15%;
    }
  }
`;

const StyledImage = styled(Image)`
  max-width: 576px;
  position: relative;
  @media (max-width: 1024px) {
    max-width: 355px;
  }
`;

export default Dashboard;
