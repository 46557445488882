import React, { FC } from 'react';
import styled from 'styled-components';
// import { CalendlyBtn } from '../../../components/CalendlyBtn';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface ManageContentProps {
  data: {
    title: string;
    firstDescription: string;
    secondDescription: string;
    buttonText: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const ManageContent: FC<ManageContentProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer
        style={{ backgroundImage: `url(${data.image.localFile.publicURL})` }}
      >
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
          {/* <StyledCalendlyBtn text={data.buttonText} /> */}
        </Content>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  padding-top: 138px;
  padding-bottom: 173px;
  @media (max-width: 1200px) {
    background-size: 60%;
  }
  @media (max-width: 1024px) {
    justify-content: flex-start;
    padding-top: 60px;
    padding-bottom: 640px;
    background-size: 768px;
  }
  @media (max-width: 600px) {
    padding-bottom: 75%;
    margin-bottom: 20px;

    background-size: 100%;
  }
`;

const Content = styled.div`
  max-width: 504px;
  @media (max-width: 1024px) {
    max-width: none;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};
    strong {
      color: ${COLORS.red};
    }
  }
  @media (max-width: 1024px) {
    max-width: 504px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;
  max-width: 370px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    max-width: none;
  }
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

// const StyledCalendlyBtn = styled(CalendlyBtn)`
//   display: none;
//   transition: opacity 0.3s;

//   &:hover {
//     opacity: 0.7;
//   }
//   @media (max-width: 1024px) {
//     display: inline-flex;
//     margin-top: 30px;
//   }
//   @media (max-width: 600px) {
//     margin-top: 20px;
//     span {
//       transform: none;
//     }
//   }
// `;

export default ManageContent;
