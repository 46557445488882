import React, { FC } from 'react';
import styled from 'styled-components';
import CircleLines from '../../../assets/images/svg/CircleLines';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface AppDashboardProps {
  data: {
    title: string;
    subtitle: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const AppDashboard: FC<AppDashboardProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <CollageWrap>
          <StyledCircleLines />
          <StyledImage localFile={data.image.localFile} alt={data.title} />
        </CollageWrap>
        <Content>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </Content>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  display: flex;

  position: relative;
  padding-left: 23px;
  padding-top: 115px;
  padding-bottom: 58px;
  @media (max-width: 1100px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 50px;
    padding-top: 60px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 0;
  }
`;

const CollageWrap = styled.div`
  position: relative;
  margin-right: 56px;
  margin-top: -22px;
  @media (max-width: 1100px) {
    margin-right: -77px;
  }
  @media (max-width: 767px) {
    align-self: center;
    margin-right: -8px;
  }
`;

const StyledCircleLines = styled(CircleLines)`
  position: absolute;
  left: -3.5%;
  top: -19%;
  width: 70.3%;
  height: auto;
`;

const StyledImage = styled(Image)`
  max-width: 697px;
  @media (max-width: 1100px) {
    width: 455px;
  }
  @media (max-width: 767px) {
    width: auto;
    max-width: 455px;
  }
`;

const Content = styled.div`
  max-width: 515px;
  @media (max-width: 767px) {
    max-width: none;
    margin-bottom: 60px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  margin-bottom: 28px;
  @media (max-width: 1100px) {
    max-width: 320px;
  }
  @media (max-width: 600px) {
    margin-bottom: 35px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  color: ${COLORS.black3};
  margin-bottom: 20px;
  font-weight: 600;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.sectionText}
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;

  max-width: 430px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default AppDashboard;
