import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { useCloudBasedDashboardCards } from '../../../hooks/useCloudBasedDashboardCards';

const CloudBasedDashboard: FC = () => {
  const cards = useCloudBasedDashboardCards();

  return (
    <Root>
      <TitleContainer>
        <Title>Dashboard features for more efficiency</Title>
      </TitleContainer>
      <CardsContainer>
        <CardsList>
          {cards.map(item => {
            return (
              <CardsItem key={item.id}>
                <IconWrap>
                  <Icon
                    localFile={item.fields.icon.localFile}
                    alt={item.title}
                  />
                </IconWrap>
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>{item.fields.description}</CardDescription>
              </CardsItem>
            );
          })}
        </CardsList>
      </CardsContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.black2};
`;

const TitleContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 60px;
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
  @media (max-width: 600px) {
    padding-bottom: 42px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};

  text-align: center;
  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 41px;
    max-width: 450px;
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    text-align: left;
    max-width: none;
  }
`;

const CardsContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 114px;
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
`;

const CardsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 600px) {
    display: block;
  }
`;

const CardsItem = styled.li`
  padding: 80px 15%;
  &:nth-child(2n) {
    padding-left: 0;
  }
  @media (max-width: 1024px) {
    padding: 45px 32px;

    &:nth-child(2n) {
      padding-left: 47px;
    }
  }
  @media (max-width: 600px) {
    &,
    &:nth-child(2n) {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 35px 16px;
    }
  }
`;

const IconWrap = styled.div`
  max-height: 80px;
  margin-bottom: 22px;
  @media (max-width: 600px) {
    margin-bottom: 25px;
  }
`;

const Icon = styled(Image)`
  width: 80px;
`;

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${COLORS.white};
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    align-self: flex-start;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 25px;
    text-align: left;
  }
`;

const CardDescription = styled.p`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  max-width: 440px;
  @media (max-width: 600px) {
    align-self: flex-start;
    max-width: none;
  }
`;

export default CloudBasedDashboard;
