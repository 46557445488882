import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { useEngagingTools } from '../../../hooks/useEngagingTools';

interface EngagingToolsProps {
  data: {
    title: string;
    subtitle: string;
    description: string;
  };
}

const EngagingTools: FC<EngagingToolsProps> = ({ data }) => {
  const cards = useEngagingTools();

  return (
    <Root>
      <StyledContainer>
        <SectionHead>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <Description>{data.description}</Description>
        </SectionHead>
        <Cards>
          {cards.map(item => {
            return (
              <Card key={item.id}>
                <CardTitle>{item.title}</CardTitle>
                <CardContent
                  dangerouslySetInnerHTML={{ __html: item.fields.list }}
                />
              </Card>
            );
          })}
        </Cards>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.red};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 172px;
  @media (max-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 110px;
  }
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 90px;
  }
  @media (max-width: 650px) {
    padding-bottom: 60px;
  }
`;

const SectionHead = styled.div`
  margin-bottom: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 1200px) {
    margin-bottom: 55px;
  }
  @media (max-width: 500px) {
    text-align: left;
    align-items: flex-start;
    margin-bottom: 40px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 1200px) {
    font-size: 36px;
    line-height: 41px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.p`
  font-size: 36px;
  line-height: 54px;
  font-weight: 600;
  color: ${COLORS.white};
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.sectionTextMobile};
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const Description = styled.p`
  font-size: 24px;
  line-height: 36px;

  color: ${COLORS.white};
  max-width: 935px;
  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.sectionTextMobile};
  }
`;

const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 16px;
  }
  @media (max-width: 650px) {
    display: block;
  }
`;

const Card = styled.li`
  background-color: ${COLORS.white};
  border-radius: 15px;
  padding: 50px 20px 63px 45px;
  @media (max-width: 1200px) {
    padding: 30px 24px;
  }
  @media (max-width: 650px) {
    margin-bottom: 20px;
    padding: 40px 10px 40px 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${COLORS.black2};
  margin-bottom: 30px;
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    text-align: left;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: left;
  }
  @media (max-width: 650px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
    text-align: left;
  }
`;

const CardContent = styled.div`
  li {
    padding-left: 25px;
    position: relative;
    font-size: 24px;
    line-height: 41px;

    color: ${COLORS.black2};
    &::before {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      background-color: ${COLORS.red};
      left: 0;
      top: 14px;
      border-radius: 50%;
    }
  }
  @media (max-width: 1200px) {
    li {
      font-size: 18px;
      line-height: 28px;
    }
  }
  @media (max-width: 1024px) {
    li {
      padding-left: 21px;
      font-size: 14px;
      line-height: 24px;
      &::before {
        width: 6px;
        height: 6px;
        top: 9px;
      }
    }
  }
  @media (max-width: 650px) {
    li {
      ${TYPOGRAPHY.sectionTextMobile};
      padding-left: 25px;
      margin-bottom: 10px;
      :last-child {
        margin-bottom: 0;
      }
      &::before {
        width: 9px;
        height: 9px;
        top: 7px;
      }
    }
  }
`;

export default EngagingTools;
