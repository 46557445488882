import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from '../../../../../components/Image';
import { COLORS } from '../../../../../constants';
import { useGreatContentTechologies } from '../../../../../hooks/useGreatContentTechologies';

import 'swiper/css';

interface ImagesSliderProps {
  className?: string;
}
const ImagesSlider: FC<ImagesSliderProps> = ({ className }) => {
  const greatContentsData = useGreatContentTechologies();
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const paginationRef = useRef<HTMLParagraphElement>(null);
  const [swiperRef, setSwiperRef] = useState<any>(null);

  return (
    <Root className={className}>
      <Swiper
        modules={[Navigation, Pagination]}
        breakpoints={{
          650: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
          },
          320: {
            slidesPerView: 1.6,
            spaceBetween: 20,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
          },
        }}
        onInit={swiper => {
          if (innerWidth < 650) {
            swiper.init();
          } else {
            swiper.disable();
          }
        }}
        onSwiper={swiper => setSwiperRef(swiper)}
        init={false}
        pagination={{
          type: 'custom',
          el: paginationRef.current,
          renderCustom: (swiper, current, total) => `${current}/${total}`,
        }}
        navigation={{
          prevEl: prevBtnRef.current!,
          nextEl: nextBtnRef.current!,
        }}
      >
        {greatContentsData.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <SliderItemWrap>
                <SliderItemTitle>{item.title}</SliderItemTitle>
                <StyledImage
                  localFile={item.fields.image.localFile}
                  alt={item.title}
                />
              </SliderItemWrap>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <NavigationWrap>
        <PrevNavigationBtn ref={prevBtnRef} />
        <PaginationCounter ref={paginationRef} />
        <NavigationBtn ref={nextBtnRef} />
      </NavigationWrap>
    </Root>
  );
};

const Root = styled.div`
  position: relative;

  padding-left: 7.5%;
  padding-right: 7.5%;

  .swiper-slide {
  }
  .swiper-slide:first-child {
  }
  @media (min-width: 1441px) {
  }
  @media (max-width: 1300px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 650px) {
    padding-top: 52px;
    padding-bottom: 37px;
    .swiper {
      margin-bottom: 20px;
    }
    .swiper-slide {
      border-left: 0;
    }
  }
`;

const StyledImage = styled(Image)`
  max-width: 217px;
`;

const NavigationWrap = styled.div`
  display: none;
  background-color: ${COLORS.red};
  @media (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 11px;
    margin: 0 auto;
    max-width: 130px;
  }
`;

const NavigationBtn = styled.button`
  cursor: pointer;
  width: 28px;
  height: 11px;
  background: url('/images/arrow-white.svg') no-repeat center;
  background-size: cover;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const PrevNavigationBtn = styled(NavigationBtn)`
  transform: rotate(180deg);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

const PaginationCounter = styled.p`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${COLORS.white};
  margin-left: 10px;
  margin-right: 10px;
`;

const SliderItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 13% 124px;
  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }
  @media (max-width: 900px) {
    padding: 20px 20px 60px;
  }
  @media (max-width: 650px) {
    padding: 0;
  }
`;

const SliderItemTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${COLORS.black2};
  margin-bottom: 40px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  @media (max-width: 650px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 40px;
  }
`;

export default ImagesSlider;
