import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { useUpgradeCards } from '../../../hooks/useUpgradeCards';

interface UpgradeAppProps {
  data: {
    title: string;
  };
}
const UpgradeApp: FC<UpgradeAppProps> = ({ data }) => {
  const cards = useUpgradeCards();

  return (
    <Root>
      <StyledContainer>
        <Title dangerouslySetInnerHTML={{ __html: data.title }} />
        <CardsList>
          {cards.map(item => {
            return (
              <CardItem key={item.id}>
                <IconWrap>
                  <Icon
                    localFile={item.fields.icon.localFile}
                    alt={item.title}
                  />
                </IconWrap>
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>{item.fields.description}</CardDescription>
              </CardItem>
            );
          })}
        </CardsList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 140px;
  @media (max-width: 900px) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};
    strong {
      color: ${COLORS.red};
    }
  }
  @media (max-width: 900px) {
    margin-bottom: 40px;
    /* h1,
    h2,
    p {
      font-size: 24px;
      line-height: 27px;
    } */
  }
  @media (max-width: 600px) {
    text-align: left;
    /* max-width: 210px; */
    margin-bottom: 45px;
  }
`;

const CardsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const CardItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 65px 30px 50px;
  background-color: ${COLORS.white};
  border-radius: 15px;
  @media (max-width: 900px) {
    padding: 45px 20px 35px;
  }
  @media (max-width: 600px) {
    padding: 35px 19px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const IconWrap = styled.div`
  max-height: 80px;
  margin-bottom: 50px;
  @media (max-width: 900px) {
    margin-bottom: 35px;
  }
  @media (max-width: 600px) {
    margin-bottom: 28px;
  }
`;

const Icon = styled(Image)`
  width: 80px;
`;

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.black3};
  margin-bottom: 25px;
  text-align: center;
  max-width: 190px;
  @media (max-width: 1200px) {
    max-width: none;
  }
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 28px;
    max-width: 190px;
  }
`;

const CardDescription = styled.p`
  ${TYPOGRAPHY.sectionText};
`;

export default UpgradeApp;
