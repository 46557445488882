import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { COLORS, TYPOGRAPHY } from '../../../../../constants';
import { useGreatContentTechologies } from '../../../../../hooks/useGreatContentTechologies';

const DescriptionSlider: FC = () => {
  const greatContentsData = useGreatContentTechologies();
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const paginationRef = useRef<HTMLParagraphElement>(null);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState('');

  const handleCurrentSlider = (id: string) => () => {
    setCurrentSlide(prev => {
      if (prev === id) {
        return '';
      }

      return id;
    });
  };

  // const handleSliderHeadClick = (e: any) => {
  //   const target: HTMLElement = e.target;
  //   const allDescriptionSlides = swiperRef.slides;
  //   allDescriptionSlides.forEach((item: any) => {
  //     const wrapper = item.querySelector('.description-slide');
  //     wrapper.classList.remove('active');
  //   });
  //   const parent = target.parentElement;
  //   const hasActiveClass = parent!.classList.contains('active');

  //   hasActiveClass
  //     ? parent?.classList.remove('active')
  //     : parent?.classList.add('active');
  // };

  return (
    <Root>
      <Swiper
        modules={[Navigation, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 1.69,
            centeredSlides: true,
            spaceBetween: 30,
            slidesOffsetBefore: 32,
            slidesOffsetAfter: 32,
          },
        }}
        onInit={swiper => {
          if (innerWidth > 768) {
            swiper.init();
          } else {
            swiper.disable();
          }
        }}
        onSwiper={swiper => setSwiperRef(swiper)}
        init={false}
        pagination={{
          type: 'custom',
          el: paginationRef.current,
          renderCustom: (swiper, current, total) => `${current}/${total}`,
        }}
        navigation={{
          prevEl: prevBtnRef.current!,
          nextEl: nextBtnRef.current!,
        }}
      >
        {greatContentsData.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <SliderItemWrapper
                className={
                  item.id === currentSlide
                    ? 'description-slide active'
                    : 'description-slide'
                }
              >
                <SliderHeadWrap onClick={handleCurrentSlider(item.id)}>
                  <Title>{item.title}</Title>
                  <TitleDuplicate>{item.title}</TitleDuplicate>
                </SliderHeadWrap>
                <SliderItemContentWrap>
                  <SliderItemContent>
                    <Subtitle>{item.fields.subtitle}</Subtitle>
                    <Description
                      dangerouslySetInnerHTML={{
                        __html: item.fields.description,
                      }}
                    />
                  </SliderItemContent>
                </SliderItemContentWrap>
              </SliderItemWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <NavigationWrap>
        <PrevNavigationBtn ref={prevBtnRef} />
        <PaginationCounter ref={paginationRef} />
        <NavigationBtn ref={nextBtnRef} />
      </NavigationWrap>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  .swiper-slide {
    display: flex;
    align-items: stretch;
    height: auto;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    .swiper-wrapper {
      display: block;
    }
    .swiper-slide {
      display: block;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const NavigationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 7.5%;
  bottom: -120px;
  padding: 43px 30px;
  max-width: 300px;
  z-index: 3;
  background-color: ${COLORS.red};
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationBtn = styled.button`
  cursor: pointer;
  width: 64px;
  height: 23px;
  background: url('/images/arrow-white.svg') no-repeat center;
  background-size: 64px 23px;
  box-sizing: content-box;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const PrevNavigationBtn = styled(NavigationBtn)`
  transform: rotate(180deg);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const PaginationCounter = styled.p`
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: ${COLORS.white};
  margin-left: 24px;
  margin-right: 24px;
`;

const SliderHeadWrap = styled.div`
  margin-bottom: 25px;
  position: relative;
  @media (max-width: 768px) {
    margin: 0;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: ${COLORS.red};

      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      transition: transform 0.3s;
      height: 21px;
      width: 1px;
      right: 10px;
    }
    &::after {
      width: 21px;
      height: 1px;
      right: 0;
    }
  }
`;

const SliderItemContentWrap = styled.div`
  transition: max-height 0.5s;
  overflow: hidden;
  @media (max-width: 768px) {
    max-height: 0;
  }
`;

const SliderItemContent = styled.div`
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 8px;
  }
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: ${COLORS.black2};
  transition: opacity 0.3s;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    pointer-events: none;
  }
`;

const TitleDuplicate = styled(Title)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-weight: 600;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: ${COLORS.black2};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 28px;

    margin-bottom: 15px;
  }
`;

const Description = styled.div`
  p {
    ${TYPOGRAPHY.sectionText};
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SliderItemWrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 25px;
  padding: 70px 80px 80px 80px;
  transition: background 0.3s ease;
  @media (max-width: 1024px) {
    padding: 40px;
  }
  @media (max-width: 768px) {
    padding: 20px 22px;
    border: 1px solid ${COLORS.red};
    border-radius: 10px;
    background-color: transparent;
    &.active {
      background-color: ${COLORS.white};
      ${Title} {
        opacity: 0;
      }
      ${TitleDuplicate} {
        opacity: 1;
      }
      ${SliderHeadWrap} {
        &::before {
          transform: translateY(-50%) rotate(90deg);
        }
      }
      ${SliderItemContentWrap} {
        max-height: 1000px;
      }
    }
  }
`;

export default DescriptionSlider;
