import { graphql, useStaticQuery } from 'gatsby';

export interface GreatContent {
  id: string;
  title: string;
  fields: {
    subtitle: string;
    description: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useGreatContentTechologies(): GreatContent[] {
  const { allWpContentTechnology } = useStaticQuery(graphql`
    query GET_GREAT_CONTENT_TECHOLOGIES {
      allWpContentTechnology {
        nodes {
          id
          title
          fields: greatContentFields {
            subtitle
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allWpContentTechnology.nodes;
}
