import { graphql, useStaticQuery } from 'gatsby';

export interface EngagingTool {
  id: string;
  title: string;
  fields: {
    list: string;
  };
}

export function useEngagingTools(): EngagingTool[] {
  const { allWpEngagingTool } = useStaticQuery(graphql`
    query GET_ENGAGING_TOOLS {
      allWpEngagingTool {
        nodes {
          id
          title
          fields: engagingToolFields {
            list
          }
        }
      }
    }
  `);

  return allWpEngagingTool.nodes;
}
