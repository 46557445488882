import { graphql, useStaticQuery } from 'gatsby';

export interface CloudBasedDashboardCard {
  id: string;
  title: string;
  fields: {
    description: string;
    icon: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useCloudBasedDashboardCards(): CloudBasedDashboardCard[] {
  const { allWpCloudBasedDashboardCard } = useStaticQuery(graphql`
    query GET_CLOUBASED_DASHBOARD_CARDS {
      allWpCloudBasedDashboardCard {
        nodes {
          id
          title
          fields: cloudBasedDashboardCardFields {
            description
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allWpCloudBasedDashboardCard.nodes;
}
