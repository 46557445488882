import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { DescriptionSlider } from './components/DescriptionSlider';
import { ImagesSlider } from './components/ImagesSlider';

interface GreatContentTechnologiesProps {
  title: string;
}

const GreatContentTechnologies: FC<GreatContentTechnologiesProps> = ({
  title,
}) => {
  return (
    <Root>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <ContentContainer>
        <StyledImagesSlider />
        <DescriptionSlider />
      </ContentContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const TitleContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 47px;
  @media (max-width: 900px) {
    padding-top: 50px;
    padding-bottom: 35px;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  text-align: center;
  /* @media (max-width: 900px) {
    font-size: 24px;
    line-height: 28px;
  } */
  @media (max-width: 500px) {
    text-align: left;
  }
`;

const ContentContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  padding-bottom: 196px;
  @media (max-width: 768px) {
    padding-bottom: 70px;
  }
  @media (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const StyledImagesSlider = styled(ImagesSlider)`
  margin-bottom: 73px;
  @media (max-width: 1024px) {
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    margin-bottom: 50px;
  }
`;

export default GreatContentTechnologies;
