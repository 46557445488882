import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { NewFeature } from '../components/NewFeature';
import { ReleaseNow } from '../components/ReleaseNow';
import { Hero } from '../pageComponents/MainPage/Hero';
import { AppDashboard } from '../pageComponents/RadioPage/AppDashboard';
import { UpgradeApp } from '../pageComponents/RadioPage/UpgradeApp';
import { EngagingTools } from '../pageComponents/RadioPage/EngagingTools';
import { AdvertisingServices } from '../pageComponents/RadioPage/AdvertisingServices';
import { Dashboard } from '../pageComponents/RadioPage/Dashboard';
import { CloudBasedDashboard } from '../pageComponents/RadioPage/CloudBasedDashboard';
import { ManageContent } from '../pageComponents/RadioPage/ManageContent';
import { GreatContentTechnologies } from '../pageComponents/RadioPage/GreatContentTechnologies';
import { Seo } from '../components/Seo';

const RadioPageTemplate = ({ pageContext }: any) => {
  const {
    heroRadioSection,
    appAndDashboardSection,
    upgradeAppSection,
    engagingToolsSection,
    dashboardSection,
    manageContentSection,
    greatContentTechologiesTitle,
  } = pageContext.radioFields;
  const newFeature = pageContext.newFeature;
  const releaseNow = pageContext.releaseNow;
  const seo = pageContext.seo;
  const title = pageContext.title;

  return (
    <MainLayout>
      <Seo
        title={seo.seoTitle ? seo.seoTitle : title}
        description={seo.seoDescription ? seo.seoDescription : ''}
      />
      <Hero data={heroRadioSection} />
      <AppDashboard data={appAndDashboardSection} />
      <UpgradeApp data={upgradeAppSection} />
      <GreatContentTechnologies title={greatContentTechologiesTitle} />
      <EngagingTools data={engagingToolsSection} />
      <NewFeature data={newFeature} />
      <AdvertisingServices />
      <Dashboard data={dashboardSection} />
      <CloudBasedDashboard />
      <ManageContent data={manageContentSection} />
      <ReleaseNow data={releaseNow} />
    </MainLayout>
  );
};
export default RadioPageTemplate;
